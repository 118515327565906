import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'


var landing = require('../images/background.jpg')

const IndexPage = () => (
  <div>
    <img style={{height: "100%", width: "100%", objectFit: "cover", position: "absolute", left: 0, top: 0}} src={landing} />
      <div style={{height: "100%", width: "100%", position: "absolute", display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center"}}>

          <p></p>
          <p></p>
            <p></p>
            <p></p>
              <p></p>
              <p></p>
          <h1>DSIT</h1>
          <p>Don Sing Innovation Technology Limited</p>
          <p>{"Our mobile game is coming soon."}</p>
          <p>{"If you are interested, please contact info@donsing.com"}</p>

      </div>

  </div>

)

export default IndexPage
